import { createSlice} from "@reduxjs/toolkit";

const initialState = {
    shelfs: [],
    activeShelf: null,
}


export const shelfsSlice = createSlice ({
    name: 'shelfs',
    initialState,
    reducers: {
        setShelfs: (state, action) => {
            const payload = action.payload;
            state.shelfs = payload;
        },
        setActiveShelf: (state, action) => {
            const payload = action.payload;
            state.activeShelf = payload;
        },
        updateOrderInLocalShelf: (state, action) => {
            const payload = action.payload;
            const shelfId = payload.shelfId;
            const order = payload.order
            state.shelfs.find(shelf => shelf.id === shelfId).order = order
        },
        addBookToShelf: (state, action) => {
            const payload = action.payload
            const book = payload.book
            const shelfId = payload.shelfId;
            let shelf = state.shelfs.find(shelf => shelf.id === shelfId)
            shelf.books = [book, ...shelf.books]
            shelf.order = [book.id, ...shelf.order]
        }
    }
})

export const { 
    setShelfs,
    setActiveShelf,
    updateOrderInLocalShelf,
    addBookToShelf
 } = shelfsSlice.actions;

export default shelfsSlice.reducer;