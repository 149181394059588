import React from "react";

import "./NavigationBar.css";

import { Link, useNavigate  } from "react-router-dom"
import { Avatar } from '@mantine/core';

import { useSelector } from "react-redux";

function NavigationBar({ showArrow, showAvater, LinkAvatar, LinkArrow }) {
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    function AvatarClick(){
        navigate(LinkAvatar);
    }

    function ArrowClick(){
        navigate(LinkArrow);
    }

    return (
        <div className="navbar">
            {showArrow==true ? (
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" onClick={ArrowClick} className="arrow">
                        <path d="M23.3334 12.8334H9.13502L15.6567 6.31169L14 4.66669L4.66669 14L14 23.3334L15.645 21.6884L9.13502 15.1667H23.3334V12.8334Z" fill="black"/>
                    </svg>
                </div>
            ) : (
                <div></div>
            )
            }
            {showAvater==true &&
                <div className="avatarContainer">
                    <Avatar src={user.profile_picture_url} radius="xl" size="35px" color="dark" onClick={AvatarClick}/>
                </div>
            }
        </div>
    )
}

export default NavigationBar;