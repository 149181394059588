import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import './Bookshelf.css'; // Import the CSS file
import Sortable from 'sortablejs'; // Import Sortable.js
import NavigationBar from "./NavigationBar";
import { Input, FileInput } from '@mantine/core';
import { storage } from "../firebase/firebase";
import { ref as ref_storage, uploadBytes, getDownloadURL, uploadString } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { uploadBook, updateOrderInShelf, deleteBook, updateBook } from "../sideEffects/sideEffects";
import { useSelector } from "react-redux";
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';

function getFileExtensionFromDataURI(dataURI) {
  // Split the data URI to extract the media type part
  const parts = dataURI.split(';');

  // Extract the media type, which should look like 'data:image/png' or 'data:image/jpeg'
  const mediaType = parts[0].substring(5);

  // Split the media type to get the file extension
  const fileExtension = mediaType.split('/')[1];

  return fileExtension;
}


const EditBook = forwardRef((props, ref) => {

  const fileRef = useRef(null)

  const activeShelf = props.activeShelf

  const [modalOpen, setModalOpen] = useState(false)

  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [bookTitle, setBookTitle] = useState(null);
  const [author, setAuthor] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [bookId, setBookId] = useState(null);

  useEffect(() => {
    console.log("props.book", props.book)
    if (props.book) {
      setBookTitle(props.book.title)
      setAuthor(props.book.author)
      setPublisher(props.book.publisher)
      setImage(props.book.imageDownloadURL)
      setBookId(props.book.id)
    }
    else {
      setBookTitle(null)
      setAuthor(null)
      setPublisher(null)
      setImage(null)
      setBookId(null)
    }
  }, [props.book])




  const open = (e) => {
    setModalOpen(true)
  }

  useImperativeHandle(ref, () => ({
    open,
  }));

  

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result); // Display the selected image
      };
      reader.readAsDataURL(file);

      setImage(file);
    }
  };

  async function getThisDownloadURL(imageId, type) {
    return new Promise(async (resolve, reject) => {
    const imageRef = ref_storage(storage, `${type}/${imageId}`);
        try {
            const imageURL = await getDownloadURL(imageRef)
            console.log("downloadURL", imageURL)
            resolve(imageURL)
        } catch (e) {
            console.log(e)
            reject(e)
        }
    })
}

  function handleUpload(imageURI) {
    return new Promise(async (resolve, reject) => {
        const newImageId = uuidv4()
        const folder = "books"
        const fileExtension = getFileExtensionFromDataURI(imageURI);
        //const fileName = `${newImageId}.${fileExtension}`;
        const storageRef = ref_storage(storage, `${folder}/${newImageId}`);
        try {
            await uploadString(storageRef, imageURI, 'data_url')
            const downloadURL = await getThisDownloadURL(newImageId, folder)
            resolve({id: newImageId, url: downloadURL})
        }
        catch (e) {
          console.log(e); reject(e) }
      })
}

async function uploadMyBook() {
  const imageUploadResult = await handleUpload(image)
  const book = {
    title: bookTitle,
    author,
    publisher,
    imageDownloadURL: imageUploadResult.url,
    imageId: imageUploadResult.id,
    shelf: activeShelf
  }
  uploadBook(book)
}

async function updateMyBook() {
  let book = {
    ...props.book,
  }

  if (props.book.imageDownloadURL !== image) {
  const imageUploadResult = await handleUpload(image)
  book.imageDownloadURL = imageUploadResult.url
  book.imageId = imageUploadResult.id
  }

  book = {
    ...book,
    title: bookTitle,
    author,
    publisher,
    shelf: activeShelf,
    id: bookId
  }

  updateBook(book)
}

  return (
    <>
    {modalOpen && <div id="searchresults" style={{zIndex:999, position: "fixed", top: 0}}>
    <Input placeholder="Buch Titel" value={bookTitle}  onChange={(event) => setBookTitle(event.currentTarget.value)}/>
    <Input placeholder="Autor" value={author}  onChange={(event) => setAuthor(event.currentTarget.value)}/>
    <Input placeholder="Verlag" value={publisher}  onChange={(event) => setPublisher(event.currentTarget.value)}/>
    
    <input type="file" onChange={handleImageUpload} />
      {image && <img src={image} alt="Selected" style={{ maxWidth: '300px' }} />}
    <Button fullWidth onClick={() => {setModalOpen(false); bookId ? updateMyBook() : uploadMyBook()}}>Fertig</Button>
    <Button fullWidth onClick={() => setModalOpen(false)}>Abbrechen</Button>
    </div>}
    </>
  )

});


function BookInfo(props){
  const book = props.book
  console.log("bookkk", book)

  const [openModal, setOpenModal] = useState(false)
  const closeAction = props.closeAction
  const editAction = props.editAction
  return(
    <>
     <div id="book-info" style={{width: 500, height: "100%", backgroundColor: "gray", position: "fixed", right: 0, top: 0, zIndex: 888}}>
      <button onClick={closeAction}>schließen</button>
      <button onClick={() => {editAction()}}>bearbeiten</button>
      <img src={book.imageDownloadURL} style={{width: "80%", height: 350, objectFit: "contain"}}/>
      <h2>{book.title}</h2>
      <p>{book.author}</p>
      <p>{book.publisher}</p>
      <a onClick={()=>setOpenModal(true)} style={{color: "red"}}>löschen</a>

    </div>
    <Modal opened={openModal} onClose={()=>setOpenModal(false)} title="Authentication">
        {/* Modal content */}
        <a onClick={()=>{setOpenModal(false);deleteBook(book.id); closeAction()}} style={{color: "red"}}>löschen</a>

      </Modal>
    </>
   
  )
}

function Bookshelf() {

  const [activeBookId, setActiveBookId] = useState(null)

  //get activeShelf from redux
  const activeShelf = useSelector((state) => state.shelfs.activeShelf);

  const editBookRef = useRef()

  //get books from activeShelf from redux
  const shelfs = useSelector((state) => state.shelfs.shelfs);
  let books = shelfs.find(shelf => shelf.id === activeShelf)?.books
  const order = shelfs.find(shelf => shelf.id === activeShelf)?.order

  function getActiveBook() {
    if (activeBookId) {
      return books.find(book => book.id === activeBookId)
    }
    else {
      return null
    }
  }

  //every book has an id and there is an array of ids which defines the order of the books
  //this function returns an array of books in the right order
  let orderedBooks = []
  if (order?.length > 0 && books?.length > 0) {
    orderedBooks = order?.map((id) => books.find((book) => book.id === id))
    console.log("orderedBooks", orderedBooks)
  }


  // const handleUpload = () => {
  //   if (image) {
  //     console.log("image", image)
  //     const storageRef = ref_storage(storage, `sdfsdjfs.png`); // Use ref_storage to create a reference
  //     uploadString(storageRef, image, 'data_url').then((snapshot) => {
  //       console.log('Image uploaded successfully');
  //       getDownloadURL(storageRef).then((url) => {
  //         setImageUrl(url);
  //       });
  //     });
  //   }
  // };


  useEffect(() => {
    // Code to initialize Sortable.js when the component mounts
    const el = document.getElementById("bookshelf");
    const sortable = new Sortable(el, { 
      animation: 150, 
      ghostClass: "ghostsAreNotWanted", 
      forceFallback: true,
      onSort: (event) => {
        console.log("onsort called 🧟‍♀️🧟‍♀️🧟‍♀️🧟‍♀️🧟‍♀️")
        // This callback is executed after each sort operation
        console.log('Sort completed:', event);

        // Get the current order of items
        const currentOrder = Array.from(el.getElementsByClassName("book-wrapper")).map((element) => element.dataset.id);
        console.log('Current order of data-ids:', currentOrder);
       updateOrderInShelf(activeShelf, currentOrder)


        // You can perform additional actions or update state here
      },
      onStart: function (/**Event*/evt, /**Event*/originalEvent) {
        el.style.cursor = 'grabbing'
      },
      onEnd: function (/**Event*/evt, /**Event*/originalEvent) {
        el.style.cursor = 'auto'
      }
    });


    // Don't forget to clean up Sortable.js when the component unmounts
    return () => {
      sortable.destroy();
    };
  }, []); // The empty dependency array ensures that this code runs once when the component mounts


  return (
    <>
    <div id="searchbar">
      {/* <input placeholder="ISBN, Name, Author" id="newbooksearch" /> */}
      <div style={{display: "flex", flexDirection: "row"}} >
        <button onClick={() => editBookRef.current.open()} style={{width: 70, borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "blue", backgroundColor: "rgba(193, 194, 197, 1)", cursor: "pointer"}}>+</button>
      <NavigationBar showArrow={false} showAvater={true} LinkAvatar="/settings" LinkArrow=""/>
      </div>
    </div>
 
    <EditBook ref={editBookRef} activeShelf={activeShelf} book={getActiveBook()}/>


    {activeBookId && <BookInfo book={getActiveBook()} closeAction={() => setActiveBookId(null)} editAction={()=>{editBookRef.current.open()}}/>}

    <div id="bookshelf">
    {
      orderedBooks?.map((book, index) => {
        return (
          <div key={book.id} className="book-wrapper" data-id={book.id} onClick={() => setActiveBookId(book.id)}>
            <img src={book.imageDownloadURL} width="300" height="500" alt="A tall book" className="book" />
          </div>
        );
      })
    }

{
      // <div className="book-wrapper">
      //   <img src="BOOKS/1.jpg" width="300" height="500" alt="A tall book" className="book" />
      // </div>
      // <div className="book-wrapper">
      //   <img src="BOOKS/2.jpg" width="250" height="350" alt="A normal book" className="book" />
      // </div>
      // <div className="book-wrapper">
      //   <img src="BOOKS/3.jpg" width="400" height="280" alt="A wide book" className="book" />
      // </div>
}
    </div>
    </>
  );
}

export default Bookshelf;
