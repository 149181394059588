import React from "react";

function PrivacyPolicy(){
    return(
        <>
        TEST
        </>
    )
}

export default PrivacyPolicy