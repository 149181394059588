import './App.css';

//Mantine
import { MantineProvider } from '@mantine/core';

//Pages:
import { I18nextProvider } from "react-i18next";
import i18next from "./i18n/i18n.config"
//Redux:
import { store } from './redux/store';
import { Provider} from 'react-redux';

//Stripe
import "@stripe/stripe-js"

import NavigationCentral from './NavigationCentral';

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
          <MantineProvider withGlobalStyles withNormalizeCSS>
             <NavigationCentral/>
          </MantineProvider>
        </I18nextProvider>
    </Provider>
  );
}

export default App;