import React from "react";

import '../Pages.css';

import { useState, useEffect} from "react";
import { Button, Text, Input} from '@mantine/core';
import NavigationBar from "../NavigationBar";
import { useNavigate  } from "react-router-dom"
import { Link } from 'react-router-dom';

import { httpsCallable } from "firebase/functions";
import { auth, functions } from '../../firebase/firebase';

import { firestore as db } from "../../firebase/firebase";
import { doc, updateDoc } from 'firebase/firestore';

import { Loader } from '@mantine/core';

import { useTranslation } from "react-i18next";

//sideEffects
import { 
    getCustomer,
    getCustomerId
} from "../../sideEffects/sideEffects";

function Subscription(){
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    const [manageSubscriptions, setManageSubscriptions] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);

    const [manageInvoices, setManageInvoices] = useState(false);
    const [invoices, setInvoices] = useState([]);

    const [manageCharges, setManageCharges] = useState(false);
    const [charges, setCharges] = useState([]);

    const [managePaymentMethods, setManagePaymentMethod] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [manageAddress, setManageAddress] = useState(false);
    const [address, setAddress] = useState([]);

    //ADRESSE
    const [name, setName] = useState('');
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    //ADRESSE

    //BUTTONS
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading5, setLoading5] = useState(false);

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false); 
    const [open3, setOpen3] = useState(false); 
    const [open4, setOpen4] = useState(false); 
    const [open5, setOpen5] = useState(false); 
    //BUTTONS

    async function getInvoices(){
        if(open2==true){
            setManageInvoices(false)
            setOpen2(false)
        }else{
            setLoading2(true)
            // const customerId = await getCustomerId(auth.currentUser.uid);
            const customerId = await getCustomer(auth);

            if (customerId) {
                const getCustomersInvoices = httpsCallable(functions, 'paymentEndpoints-getCustomersInvoices');
                const result = await getCustomersInvoices({customer_id: customerId})
                setInvoices(result.data.invoices.data)
                console.log(result.data.invoices.data)
                setManageInvoices(true)
            }

            setLoading2(false)

            setOpen2(true)
        }
    }

    async function getCharges(){
        if(open1==true){
            setManageCharges(false)
            setOpen1(false)
        }else{
            setLoading1(true)

            const customerId = await getCustomerId(auth.currentUser.uid);

            if (customerId) {
                const getCustomersCharges = httpsCallable(functions, 'paymentEndpoints-getCustomersCharges');
                const result = await getCustomersCharges({customer_id: customerId})
                setCharges(result.data.charges.data)
                console.log(result.data.charges.data)
                setManageCharges(true)
            }
            setLoading1(false)

            setOpen1(true)
        }
    }

    async function getPaymentMethods(){
        if(open4==true){
            setManagePaymentMethod(false)
            setOpen4(false)
        }else{
            setLoading4(true)

            const customerId = await getCustomerId(auth.currentUser.uid); 

            if (customerId) {
                const getCustomersPaymentMethods = httpsCallable(functions, 'paymentEndpoints-getCustomersPaymentMethods');
                const result = await getCustomersPaymentMethods({customer_id: customerId})
                setPaymentMethods(result.data.paymentMethods.data)
                console.log(result.data.paymentMethods.data)
                setManagePaymentMethod(true)
            }

            setLoading4(false)

            setOpen4(true)
        }  
    }

    async function detachCustomersPaymentMethods(paymentMethod_id){
        try{
            const detachCustomersPaymentMethods = httpsCallable(functions, 'paymentEndpoints-detachCustomersPaymentMethods');
            const result = await detachCustomersPaymentMethods({
                paymentMethod_id: paymentMethod_id,
            })
        }catch(error){
            console.log(error)
        }   
    }

    async function setPaymentMethodAsDefault(paymentMethod_id){
        console.log("setPaymentMethodAsDefault")
        const customerId = await getCustomerId(auth.currentUser.uid);
        // console.log(customerId)

        try{
            //STRIPE
            const setPaymentMethodAsDefault = httpsCallable(functions, 'paymentEndpoints-setPaymentMethodAsDefault');
            const result = await setPaymentMethodAsDefault({
                paymentMethod_id: paymentMethod_id,
                customer_id: customerId
            })
            console.log(result)
            //STRIPE ENDE

            //FIRESTORE
            try {
                const userDocRef = doc(db, 'users', auth.currentUser.uid);
                try {
                    await updateDoc(userDocRef, {
                        default_payment_method: paymentMethod_id
                    });
                } catch (error) {
                    console.error('Fehler beim Setzen der default_payment_method:', error);
                }
            } catch (error) {
                console.error('Fehler:', error);
            }
            //FIRESTORE ENDE
        }catch(error){
            console.log(error)
        }
    }

    async function cancelSubscription(subscriptionId) {
        try{
            const cancelSubscription = httpsCallable(functions, 'paymentEndpoints-cancelSubscription');
            const result = await cancelSubscription({
                subscription_id: subscriptionId
            })
            console.log('Subscription beendet:', subscriptionId);
        }  
        catch{
            console.log("Fehler")
        }
    }

    function formatDate(unixTimestamp) {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const date = new Date(unixTimestamp * 1000); // Unix-Timestamp in Millisekunden umwandeln
        return date.toLocaleDateString(undefined, options);
    }

    async function getProductInformation(product_id) {
        const getProduct = httpsCallable(functions, 'paymentEndpoints-getProdukt');
        try {
            const result = await getProduct({ product_id: product_id });
            const product = result.data.product;
            return product;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    }

    async function getSubscriptionsWithProductDetails() {
        if(open5==true){
            setManageSubscriptions(false)
            setOpen5(false)
        }else{
            setLoading5(true)

            const customerId = await getCustomerId(auth.currentUser.uid);
        
            if (customerId) {
                const getSubscriptions = httpsCallable(functions, 'paymentEndpoints-getSubscriptions');
                const result = await getSubscriptions({ customer_id: customerId });
        
                const subscriptionsWithProductDetails = [];
        
                for (const subscription of result.data.subscriptions) {
                    const productDetails = await getProductInformation(subscription.items.data[0].price.product);
                    if (productDetails) {
                        subscription.productDetails = productDetails;
                        subscriptionsWithProductDetails.push(subscription);
                    }
                }

                console.log(subscriptionsWithProductDetails)
                setSubscriptions(subscriptionsWithProductDetails);
                setManageSubscriptions(true)
            }

            setLoading5(false)

            setOpen5(true)
        }
    }

    async function addNewPaymentMethod(){
        console.log("addNewPaymentMethod")
        const customerId = await getCustomerId(auth.currentUser.uid);
        const currentURL = window.location.href;

        try {
            const addNewPaymentMethod1 = httpsCallable(functions, 'paymentEndpoints-addNewPaymentMethod1');
            const session = await addNewPaymentMethod1({ 
                customer_id: customerId,
                url: currentURL
            });
            console.log(session)

            const stripe = await window.Stripe('pk_test_51Nak8lHRkD8LDkz8zr5ImexThwHMVyCt9aWlRsPsI1zqZrs1jULs0xbBa4VwmmQMlDz7jpe53hwlw95fz8HnDhJE00qK180Pij');

            const result = await stripe.redirectToCheckout({
                sessionId: session.data.session.id,
            });
        }catch(error){
            console.log(error)
        }
    }

    async function updateAddress(){
        if(open3==true){
            setOpen3(false)
            setManageAddress(false)
        }else{
            setLoading3(true)

            const customerId = await getCustomerId(auth.currentUser.uid);

            if (customerId) {
                const getCustomer = httpsCallable(functions, 'paymentEndpoints-getCustomer');
                const result = await getCustomer({ customer_id: customerId });
                console.log(result)
                setAddress(result);
            }

            setLoading3(false)

            setOpen3(true)
        }
    }

    async function updateCustomerAdress(){
        const customerId = await getCustomerId(auth.currentUser.uid);

        if (customerId ){
            try {
                const updateCustomerAdress = httpsCallable(functions, 'paymentEndpoints-updateCustomerAdress');
                const result = await updateCustomerAdress({
                    customer_id: customerId,
                    name: name,
                    line1: line1,
                    line2: line2,
                    city: city,
                    state: state,
                    postalCode: postalCode,
                    countryCode: countryCode,
                })
                console.log(result)
            }catch(error){
                console.error(error)
            }
        }
    }

    useEffect(() => {
        if (address.data) {
            setName(address.data.customer.name);
            setLine1(address.data.customer.address.line1);
            setLine2(address.data.customer.address.line2);
            setCity(address.data.customer.address.city);
            setState(address.data.customer.address.state);
            setPostalCode(address.data.customer.address.postal_code);
            setCountryCode(address.data.customer.address.country);
            
            setManageAddress(true);
        }
    }, [address]);

    return(
        <div className='Container'>
            <NavigationBar showArrow={true} showAvater={true} LinkAvatar="/settings" LinkArrow="/settings"/>
            <div className="ContentContainer">
                <Text className='TitleText'>{t("subscriptions")}</Text>
                <Text className='InfoText'>{t("subscriptionsInfo")}</Text>

                <div className='Button'>
                    <Button variant="filled" disabled={loading1} color="dark" fullWidth size="md" onClick={() => {getCharges()}}>
                        {open1 ? 
                        t("closeReceipts") : 
                            <>{loading1 ? <Loader color="gray" size="sm" variant="dots" /> : t("getReceipts")}</>
                        }
                    </Button>
                </div>
                {manageCharges && (
                    <div>
                    {charges.map((charge) => (
                        <div key={charge.id} className="charge item">
                            <a href={charge.receipt_url} target="_blank">{t("receipt")}</a> <span>{t("forPrice")} {charge.amount / 100}€ {t("onDate")} {formatDate(charge.created)}</span>
                        </div>
                    ))}
                </div>
                )}
                <div className='Button'>
                    <Button variant="filled" color="dark" disabled={loading2} fullWidth size="md" onClick={() => {getInvoices()}}>
                        {open2 ?
                        t("closeInvoices") :
                            <>{loading2 ? <Loader color="gray" size="sm" variant="dots" /> : t("getInvoices")}</>
                        }
                    </Button>
                </div>
                {manageInvoices && (
                    <div>
                    {invoices.map((invoice) => (
                        <div key={invoice.id} className="invoice item">
                            <a href={invoice.hosted_invoice_url} target="_blank">{t("invoice")}</a> <span>{t("forPrice")} {invoice.amount_paid / 100}€ {t("onDate")} {formatDate(invoice.created)}</span>
                        </div>
                    ))}
                </div>
                )}
                <div className='Button'>
                    <Button variant="filled" color="dark" disabled={loading3} fullWidth size="md" onClick={() => {updateAddress()}}>
                        {open3 ?
                        t("closeAddress") :
                            <>{loading3 ? <Loader color="gray" size="sm" variant="dots" /> : t("updateAddress")}</>
                        }
                    </Button>
                </div>
                {manageAddress && (
                    <div>
                        <Input
                            className='LoginInput'
                            placeholder={t("name")}
                            required
                            value={name}
                            size="md"
                            variant="filled"
                            onChange={(event) => setName(event.target.value)}
                        />
                        <Input
                            className='LoginInput'
                            placeholder={t("line1")}
                            required
                            value={line1}
                            size="md"
                            variant="filled"
                            onChange={(event) => setLine1(event.target.value)}
                        />
                        <Input
                            className='LoginInput'
                            placeholder={t("line2")}
                            required
                            value={line2}
                            size="md"
                            variant="filled"
                            onChange={(event) => setLine2(event.target.value)}
                        />
                        <Input
                            className='LoginInput'
                            placeholder={t("city")}
                            required
                            value={city}
                            size="md"
                            variant="filled"
                            onChange={(event) => setCity(event.target.value)}
                        />
                        <Input
                            className='LoginInput'
                            placeholder={t("state")}
                            required
                            value={state}
                            size="md"
                            variant="filled"
                            onChange={(event) => setState(event.target.value)}
                        />
                        <Input
                            className='LoginInput'
                            placeholder={t("postalCode")}
                            required
                            value={postalCode}
                            size="md"
                            variant="filled"
                            onChange={(event) => setPostalCode(event.target.value)}
                        />
                        <Input
                            className='LoginInput'
                            placeholder={t("countryCode")}
                            required
                            value={countryCode}
                            size="md"
                            variant="filled"
                            onChange={(event) => setCountryCode(event.target.value)}
                        />
                        <Button variant="filled" color="green" size="md" onClick={() => {updateCustomerAdress()}}>{t("confirm")}</Button>
                </div>
                )}
                <div className='Button'>
                    <Button variant="filled" color="dark" disabled={loading4} fullWidth size="md" onClick={() => {getPaymentMethods()}}>
                        {open4 ? 
                        t("closePaymentMethods") :
                            <>{loading4 ? <Loader color="gray" size="sm" variant="dots" /> : t("updatePaymentMethods")}</>
                        }
                    </Button>
                </div>
                {managePaymentMethods && (
                    <div>
                    {paymentMethods.map((paymentMethod) => (
                        <div key={paymentMethod.id} className="paymentMethod item">
                            <span>{paymentMethod.card.brand} {t("endingInNumber")} {paymentMethod.card.last4} {t("expiresDate")} {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</span>
                            <br/>
                            <span onClick={()=>detachCustomersPaymentMethods(paymentMethod.id)} style={{ cursor: 'pointer', color: '#FF0000', fontWeight: 600}}>{t("delete")}</span> <span>{t("or")}</span> <span onClick={()=>setPaymentMethodAsDefault(paymentMethod.id)} style={{ cursor: 'pointer', color: '#008000', fontWeight: 600 }}>{t("setAsDefault")}</span>
                        </div>
                    ))}
                    <Button variant="filled" color="green" fullWidth size="md" onClick={() => addNewPaymentMethod()}>Add new card</Button>
                </div>
                )}
                <div className='Button'>
                    <Button variant="filled" disabled={loading5} color="dark" fullWidth size="md" onClick={() => {getSubscriptionsWithProductDetails()}}>
                        {open5 ?
                        t("closeSubscriptions") :
                            <>{loading5 ? <Loader color="gray" size="sm" variant="dots" /> : t("manageSubscriptions")}</>
                        }
                    </Button>
                </div>
                {manageSubscriptions && (
                    <div>
                        {subscriptions.map((subscription) => (
                            <div key={subscription.id} className="subscription item">
                                <p>{t("price")} {subscription.items.data[0].price.unit_amount_decimal / 100}€</p>
                                <p>{t("subscribedSince")} {formatDate(subscription.created)}</p>
                                <p>{t("productName")} {subscription.productDetails.name}</p>
                                <Button color="red" onClick={() => cancelSubscription(subscription.id)}>{t("cancelSubscription")}</Button>
                            </div>
                        ))}
                    </div>
                )}
                <div className='Button'>
                    <Button variant="filled" color="dark" fullWidth size="md" onClick={() => {navigate('/subscription/plans');}}>{t("plans")}</Button>
                </div>
            </div>
        </div>
    )
}

export default Subscription;