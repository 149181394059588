import React from "react";

import './Pages.css'

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconAt } from '@tabler/icons-react';
import { Button, Input, Text, Alert } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { resetPassword } from "../sideEffects/sideEffects";
import FooterBar from "../components/FooterBar";

function ForgotPassword(){
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    function clientSideValidation() {
        if (email === "") {
            setError(t("auth/missing-email"))
            return false
        }
        return true
      }

    async function ResetSubmit(){

        if (!clientSideValidation()) {
            return
        }
        setError('')
        setLoading(true)

        try{    
            await resetPassword(email)
        }catch (error) {
            console.log("error", error.message)
            setLoading(false)
            if(error.code === "auth/user-not-found"){
              setError(t("auth/user-not-found-password-reset"))
            }else{
              setError(t(error.code))
            }
        }
            setLoading(false)
    }

    return(
        <div className='Container'>
            <div className="ContentContainer">
                <Text className='TitleText'>{t("forgotPassword")}</Text>
                <Text className='InfoText'>{t("dontWorry")}</Text>
                <div className='ContextInputs'>
                    <Input
                        className='LoginInput'
                        icon={<IconAt />}
                        placeholder={t("emailInput")}
                        required
                        value={email}
                        size="md"
                        variant="filled"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
                { error && (
                    <div className="ErrorMessageNew">
                        <p>{error}</p>
                    </div>
                )}
                <div className='Button'>
                    <Button fullWidth color="dark" disabled={loading} size="md" onClick={() => {ResetSubmit()}}>{t("sendCode")}</Button>
                </div>
                <div className="ExtraLink">
                    <p>{t("rememberPassword")}{' '}
                    <Link to="/login">{t("signInHere")}</Link>
                    </p>
                </div>
            </div>
            <FooterBar/>
        </div>
    )
}
export default ForgotPassword;