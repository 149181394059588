import React from "react";

import '../Pages.css';

import { Button, Input, Text } from '@mantine/core';
import { IconAt } from '@tabler/icons-react';
import { useState } from 'react';
import NavigationBar from "../NavigationBar";
import { useTranslation } from "react-i18next";
import { SetNewEmail } from "../../sideEffects/sideEffects";
import { useNavigate  } from "react-router-dom"

function ChangeEmail(){
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const navigate = useNavigate();

    function changeEmail(){
        if(email===email2){
            SetNewEmail(email);
            navigate('/');
        }else{
            console.log("ungleiche Eingabe")
        }
    }
    return(
        <div className='Container'>
            <NavigationBar showArrow={true} showAvater={true} LinkAvatar="/settings" LinkArrow="/settings"/>
            <div className="ContentContainer">
                <Text className='TitleText'>{t("changeEmail")}</Text>
                <br></br>
                <div className='ContextInputs'>
                <Input
                    className='LoginInput'
                    icon={<IconAt />}
                    placeholder={t("newEmail")}
                    required
                    value={email}
                    size="md"
                    variant="filled"
                    onChange={(event) => setEmail(event.target.value)}
                />
                {email===email2 ?
                <Input
                    className='LoginInput'
                    icon={<IconAt />}
                    placeholder={t("repeatNewEmail")}
                    required
                    value={email2}
                    size="md"
                    variant="filled"
                    onChange={(event) => setEmail2(event.target.value)}
                />
                :
                <Input
                    className='LoginInput'
                    icon={<IconAt />}
                    placeholder={t("repeatNewEmail")}
                    required
                    value={email2}
                    size="md"
                    variant="filled"
                    error
                    onChange={(event) => setEmail2(event.target.value)}
                />
                }
                </div>
                <br></br>
                <div className='Button'>
                    <Button fullWidth color="dark" size="md" onClick={() => {changeEmail()}}>{t("changeEmail")}</Button>
                </div>
                {/* <div className="ExtraLink">
                    <p>Everything's ok?{' '}
                    <Link to="/settings">Go back</Link>
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default ChangeEmail;