import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator} from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";
import { getStorage, connectStorageEmulator  } from "firebase/storage";

import { getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyD_75ubtemWbo6x6Cnpj78C0b76lQ5GeRQ",
  authDomain: "bookbase-401b4.firebaseapp.com",
  projectId: "bookbase-401b4",
  storageBucket: "bookbase-401b4.appspot.com",
  messagingSenderId: "434621810219",
  appId: "1:434621810219:web:7e27428142a21a7a898c6e"
};

const app = initializeApp(firebaseConfig);

//Firebase Emulator:



const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const firestore = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

if (window.location.hostname.includes("localhost")){
  connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}

export {auth, provider, firestore, functions, storage, app}


