import React from "react";

import { httpsCallable } from "firebase/functions";
import { auth, functions, storage } from '../firebase/firebase';

import { firestore as db } from "../firebase/firebase";
import { doc, getDoc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';

import { signInWithPopup, updateEmail, updatePassword, deleteUser, sendPasswordResetEmail, createUserWithEmailAndPassword, signOut, getAuth, signInWithEmailAndPassword  } from "firebase/auth";
import { resolvePath, useNavigate  } from "react-router-dom"
import { provider } from "../firebase/firebase";

import { useSelector } from "react-redux";
import { store } from '../redux/store'
import { setUser } from "../redux/userSlice";

import { getDownloadURL, ref as ref_storage, uploadBytesResumable, uploadString } from "firebase/storage";

import { v4 as uuidv4 } from 'uuid';

import { ref, deleteObject } from "firebase/storage";
import { addBookToShelf, setActiveShelf, setShelfs, updateOrderInLocalShelf } from "../redux/shelfsSlice";
import { get } from "sortablejs";

async function UpdatedUserState(){
    const result = await getOwnUserObject()
    store.dispatch(setUser(result));
}

async function deleteProfilePicture({user}){
    if(user.profile_picture_id){
        console.log("JA")
        const imagePath = `images/${user.profile_picture_id}`;
        const pathtRef = ref(storage, imagePath)
        const result = await deleteObject(pathtRef)
        try{
            const userRef = doc(db, 'users', auth.currentUser.uid);
            await updateDoc(userRef, {
                profile_picture_id: '',
                profile_picture_url: ''
            });
        }catch(e){
            console.log(e)
        }
        UpdatedUserState()
    }
}

async function getOwnUserObject(){
    return new Promise(async (resolve, reject) => {
        try {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists) {
                resolve(userDocSnapshot.data())
            } else {
                console.log('Das Dokument existiert nicht.');
                reject()
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten aus Firestore:', error);
            reject(error)
        }
    })
}

async function updateUserProfilePicture({id, url}){
    try{
        const userRef = doc(db, 'users', auth.currentUser.uid);
        
        await updateDoc(userRef, {
            profile_picture_id: id,
            profile_picture_url: url
        });
    }catch(e){
        console.log(e)
    }
}

async function getThisDownloadURL(imageId, type) {
    return new Promise(async (resolve, reject) => {
    const imageRef = ref_storage(storage, `${type}/${imageId}`);
        try {
            const imageURL = await getDownloadURL(imageRef)
            resolve(imageURL)
        } catch (e) {
            console.log(e)
            reject(e)
        }
    })
}

function uploadImage(imageURI) {
    return new Promise(async (resolve, reject) => {
        let newImageId = uuidv4()
        const storageRef = ref_storage(storage, `images/${newImageId}`);
        try {
            console.log("imageURI", imageURI)
            await uploadString(storageRef, imageURI, 'data_url')
            console.log(`successfully uploaded to Firebase Storage`);
            const downloadURL = await getThisDownloadURL(newImageId, "images")
            resolve({id: newImageId, url: downloadURL})
        }
        catch (e) {
          console.log(e); reject(e) }
      })
}

const getCustomerId = async (userId) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const customerId = userData.customer_id;
            return customerId;
        } else {
            console.log('Benutzerdokument nicht gefunden');
            return null;
        }
    } catch (error) {
        console.error('Fehler beim Abfragen des customerId:', error);
        return null;
    }
};

async function getCustomer(auth) {
    try {
      const customerId = await getCustomerId(auth.currentUser.uid);
      return customerId;
    } catch (error) {
      console.error('Fehler beim Abrufen der Kunden-ID:', error);
      throw error;
    }
  }
  
async function login(email, password){
    return new Promise(async (resolve, reject) => {
        const auth = getAuth();
        try {
            const result = await signInWithEmailAndPassword(auth, email, password)
            resolve(result)
        } catch (error) {
            reject(error)
        }
    })
}

async function register(email, password) {
    return new Promise(async (resolve, reject) => {
        const auth = getAuth();
        try {
            const result = await createUserWithEmailAndPassword(auth, email, password)
            resolve(result)
        } catch (error) {
            reject(error)
        }
    })
}

async function SignInWithGoogle(){
    return new Promise(async (resolve, reject) => {
        const auth = getAuth();
        try{
            const result = await signInWithPopup(auth, provider)
            resolve(result)
        } catch (error) {
            reject(error)
        }

    })
}

async function deleteUserAccount() {
    return new Promise(async (resolve, reject) => {
        const user = auth.currentUser;
        try{
            const result = deleteUser(user)
            console.log("Account erfolgreich gelöscht")
            try{
                //Firestore document delete
                const auth = getAuth();
                const userRef = doc(db, 'users', auth.currentUser.uid);
                await deleteDoc(userRef);
            } catch (error) {
                console.log("error", error)
                reject (error)
            }
            resolve(result)
        } catch (error) {
            reject (error)
        }
    })
}


async function uploadBook(book) {
    console.log("book", book)
     const uploadBook = httpsCallable(functions, 'bookEndpoints-addBook');
    const result = await uploadBook({book});
    const activeShelf = store.getState().shelfs.activeShelf
    store.dispatch(addBookToShelf({book: result.data.book, shelfId: activeShelf}))
    console.log("result", result)
    return result;
}

function logout(){
    const auth = getAuth();
    return signOut(auth)
}

function SetNewEmail(email) {
    updateEmail(auth.currentUser, email)
      .then(() => {
        console.log("Email updated!");
      })
      .catch((error) => {
        console.log("Error");
      });
  }


function SetNewPassword(password){
    updatePassword(auth.currentUser, password).then(() => {
        console.log("Password updated!")
      }).catch((error) => {
        console.log("Error")
      });
}

function resetPassword(email){
    const auth = getAuth();
    return sendPasswordResetEmail(auth, email)
}

//function getShelfs calls the cloud fucntion getShelfs
async function getShelfs(){
    return new Promise(async (resolve, reject) => {
        const getShelfsCloudFunction = httpsCallable(functions, 'bookEndpoints-getShelfs');
        try {
            const result = await getShelfsCloudFunction()
            console.log("result", result)

            let shelfs = result.data.shelfs
            
            const activeShelfId = result.data.shelfs[0].id
            store.dispatch(setActiveShelf(activeShelfId))
            const booksForActiveShelf = await getBooks(activeShelfId)
            console.log("booksForActiveShelf", booksForActiveShelf)

            shelfs[0].books = booksForActiveShelf

            store.dispatch(setShelfs(shelfs))
            resolve(result)
        } catch (error) {
            reject(error)
        }
    })
}

async function getBooks(shelfId){
    return new Promise(async (resolve, reject) => {
        const getBooksCloudFunction = httpsCallable(functions, 'bookEndpoints-getBooks');
        try {
            const result = await getBooksCloudFunction({shelfId})
            console.log("result", result)
            resolve(result.data.books)
        } catch (error) {
            reject(error)
        }
    })
}

const addUserDocument = async () => {
    try {
        const userUid = auth.currentUser.uid
        const userDocRef = doc(db, 'users', userUid);

        try {
            await setDoc(userDocRef, {
                counter: 0,
                email: auth.currentUser.email,
                uid: auth.currentUser.uid,
                customer_id: '',
                default_payment_method: '',
                profile_picture_id: '',
                profile_picture_url: ''
            });
        } catch (error) {
            console.error('Fehler beim erstellen des Docs:', error);
        }
    } catch (error) {
        console.error('Fehler beim Hinzufügen des Dokuments:', error);
    }
};

//function to update the order in shelf
function updateOrderInShelf(shelfId, newOrder) {
        console.log("shelfId", shelfId)
        console.log("newOrder", newOrder)
    return new Promise((resolve, reject) => {
        //
        store.dispatch(updateOrderInLocalShelf({shelfId, order: newOrder}))
        const updateOrderInShelfCloudFunction = httpsCallable(functions, 'bookEndpoints-updateShelfOrder');
        updateOrderInShelfCloudFunction({shelfId, order: newOrder})
        .then(async (result) => {
            //console.log(result)
            // if (result.data) {
            //     store.dispatch(setCreators(result.data))
            // }
            resolve(result)
            
        })
        .catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

function deleteBook(bookId) {
    return new Promise((resolve, reject) => {
        const deleteBookCloudFunction = httpsCallable(functions, 'bookEndpoints-deleteBook');
        const activeShelf = store.getState().shelfs.activeShelf
        deleteBookCloudFunction({bookId, shelfId: activeShelf})
        .then(async (result) => {
            //console.log(result)
            // if (result.data) {
            //     store.dispatch(setCreators(result.data))
            // }

            //remove book from shelf

            const shelfs = store.getState().shelfs.shelfs
            const activeShelf = store.getState().shelfs.activeShelf
            const shelfIndex = shelfs.findIndex(shelf => shelf.id === activeShelf)
            const shelf = {...shelfs[shelfIndex]}
            shelf.books = shelf.books.filter(book => book.id !== bookId)
            shelf.order = shelf.order.filter(id => id !== bookId)
            const newShelfs = [...shelfs]
            newShelfs[shelfIndex] = shelf
            store.dispatch(setShelfs(newShelfs))
          
            resolve(result)
            
        })
        .catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

//update book
function updateBook(book) {
    
    return new Promise((resolve, reject) => {
        const updateBookCloudFunction = httpsCallable(functions, 'bookEndpoints-updateBook');
        updateBookCloudFunction({book})
        .then(async (result) => {
            
            //update book in shelf
            const shelfs = store.getState().shelfs.shelfs
            const activeShelf = store.getState().shelfs.activeShelf
            const shelfIndex = shelfs.findIndex(shelf => shelf.id === activeShelf)
            const newShelf = {...shelfs[shelfIndex]}
            const bookIndex = newShelf.books.findIndex(_book => _book.id === book.id)

            const newBooks = [...newShelf.books]
            newBooks[bookIndex] = book
            newShelf.books = newBooks
            const newShelfs = [...shelfs]
            newShelfs[shelfIndex] = newShelf
            store.dispatch(setShelfs(newShelfs))

            resolve(result)
            
        })
        .catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

export { 
    getCustomer, 
    getCustomerId,
    login,
    SignInWithGoogle,
    register,
    addUserDocument,
    deleteUserAccount,
    logout,
    SetNewEmail,
    SetNewPassword,
    resetPassword,
    uploadImage,
    uploadBook,
    updateBook,
    getShelfs,
    deleteBook,
    updateOrderInShelf,
    updateUserProfilePicture,
    getOwnUserObject,
    deleteProfilePicture
};