import { configureStore, combineReducers } from '@reduxjs/toolkit'
import counterReducer from './counterSlice';
import userReducer from './userSlice'
import shelfsSlice from './shelfsSlice';

import {
    persistStore,
    persistReducer,
    createMigrate,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const migrations = {
    // 3: (state) => {
    //   // migration clear out device state
    //   return {
    //     ...state,
    //     shelfs: {...state.shelfs}
    //   }
    // }
  }

const persistConfig = {
    key: 'root',
    version: 3,
    storage,
    migrate: createMigrate(migrations, { debug: false }),

}

const rootReducer = combineReducers({ user: userReducer, counter: counterReducer, shelfs: shelfsSlice });
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export const persistor = persistStore(store)