import React from "react";

import "./FooterBar.css";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function FooterBar() {
    const { t } = useTranslation();
    return (
        <div className="footerClass">
            <div className="footerLinks">
                <Link to="/impressum" className="footerLink">
                    <p>{t("impressum")}</p>
                </Link>
                <Link to="/privacy-policy" className="footerLink">
                    <p>{t("privacyPolicy")}</p>
                </Link>
                <Link to="/terms-and-conditions" className="footerLink">
                    <p>{t("termsAndConditions")}</p>
                </Link>
            </div>
        </div>
    )
}

export default FooterBar;