import { auth } from "./firebase/firebase";
import { store } from './redux/store'
import { setUser } from "./redux/userSlice";
import { getOwnUserObject, getShelfs } from "./sideEffects/sideEffects";

auth.onAuthStateChanged(async function (user) {
    if (user) {
        if (user.uid) {
            // store.dispatch(setUser(user.uid));
            try{
                const result = await getOwnUserObject();
                console.log("result", result)
                store.dispatch(setUser(result));
                getShelfs()
            } catch(e){
                console.log(e)
            }
        }
    } else {
        store.dispatch(setUser(null));
        console.log("null user")
    }
});