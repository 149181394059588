import { createSlice} from "@reduxjs/toolkit";
import {auth} from "../firebase/firebase"
import {ref, set, remove, get } from 'firebase/database';

import { firestore } from "../firebase/firebase";
import { doc, updateDoc, getDoc } from 'firebase/firestore';

const initialState = {
    count: 0
}

//Wenn ein Wert geändert wird
const SetCounterFirebase = async (newCount) => {
    const userUid = auth.currentUser.uid;
    const db = firestore
    const userDocRef = doc(db, 'users', userUid);

    try {
        await updateDoc(userDocRef, {
            counter: newCount
        });
    } catch (error) {
        console.error('Fehler beim Setzen des Zählers:', error);
    }
};

//Wenn noch kein Counter existiert
export async function SetNewCounterFirebase(newCount, user) {
    const userUid = user.uid;
    const db = firestore
    const userDocRef = doc(db, 'users', userUid);

    try {
        await updateDoc(userDocRef, {
            counter: newCount
        });
    } catch (error) {
        console.error('Fehler beim Setzen des Zählers:', error);
    }
}

//Beim Aufrufen wird der aktueller Counter Wert abgerufen
export async function GetCounterFirebase() {
    const userUid = auth.currentUser.uid;
    const db = firestore;
    const userDocRef = doc(db, 'users', userUid);

    try {
        const docSnapshot = await getDoc(userDocRef);

        if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            const countNew = userData.counter;
            return countNew;
        } else {
            console.log('Das Benutzerdokument existiert nicht.');
            return null; // Oder einen Standardwert zurückgeben, wenn das Dokument nicht existiert
        }
    } catch (error) {
        console.error('Fehler beim Abrufen des Zählerwerts:', error);
        throw error;
    }
}


export const counterSlice = createSlice ({
    name: 'counter',
    initialState,
    reducers: {
        increment: (state) => {
            state.count += 1;
            SetCounterFirebase(state.count);
        },
        decrement: (state) => {
            state.count -= 1;
            SetCounterFirebase(state.count);
        },
        setValue: (state, action) => {
            state.count = action.payload;
            SetCounterFirebase(state.count);
        },
    }
})

export const {increment, decrement, setValue} = counterSlice.actions;

export default counterSlice.reducer;