import React from "react";

import '../Pages.css';
import './Plans.css';

import { Button } from '@mantine/core';
import NavigationBar from "../NavigationBar";

import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/firebase";
import StandartButton from "../../components/StandartButton";

import { useState } from "react";

import { Loader } from '@mantine/core';

function Plans(){
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false); 


    const item_abo_startup = {
        price: "price_1NaxyOHRkD8LDkz8VBETp9wr",
        quantity: 1
    };

    const item_abo_buisness = {
        price: "price_1Nb12mHRkD8LDkz8WHa9Kf8d",
        quantity: 1
    };

    const item_abo_agency = {
        price: "price_1Nb13EHRkD8LDkz80MGIuuSF",
        quantity: 1
    };

    const lineItems = [];

    function addItem(item) {
        lineItems.push(item);
    }

    const handleCheckout = async (item_name) => {
        const currentURL = window.location.href;

        setLoading(true)
        if(item_name==item_abo_startup){
            setLoading1(true)
        }else if(item_name==item_abo_buisness) {
            setLoading2(true)
        }else if(item_name==item_abo_agency) {
            setLoading3(true)
        }
        const createCheckoutSession = httpsCallable(functions,'paymentEndpoints-createCheckoutSession');

        addItem(item_name)
        
        try {
          const session = await createCheckoutSession({
            items: lineItems,
            url: currentURL
          });
          
          const stripe = await window.Stripe('pk_test_51Nak8lHRkD8LDkz8zr5ImexThwHMVyCt9aWlRsPsI1zqZrs1jULs0xbBa4VwmmQMlDz7jpe53hwlw95fz8HnDhJE00qK180Pij');
          
          const result = await stripe.redirectToCheckout({
            sessionId: session.data.id,
          });

          if (result.error) {
            console.error(result.error.message);
          }
        } catch (error) {
          console.error(error);
        }
        setLoading(false)
        setLoading1(false)
        setLoading2(false)
        setLoading3(false)
    };

    return(
        <>
        <NavigationBar showArrow={true} showAvater={true} LinkAvatar="/settings" LinkArrow="/subscription"/>
        <div className="Header">
            <div className="HeaderTitle">
                <p>
                Become a superhero!
                <br></br>
                Change your life forever!
                </p>
            </div>
          

        </div>
       
        <div className="SubscriptionList">
            <div className="SubscriptionCard">
                <p className="SubscriptionName">Startup</p>
                <p className="SubscriptionPrice">$14</p>
                <p className="SubscriptionInfo">per user, per month</p>
                <div className="SubscriptionPayButton">
                    <Button variant="outline" color="blue" disabled={loading} size="md" onClick={()=>handleCheckout(item_abo_startup)}>
                        {loading1 ? <Loader color="gray" size="sm" variant="dots" /> : "Start My 15-day Trial"}
                    </Button>
                </div>
                <div className="SubscriptionDetails">5 Social Profiles</div>
                <div className="SubscriptionDetails">5 Scheduled Posts Per Profile</div>
                <div className="SubscriptionDetails">400+ Templates</div>
                <div className="SubscriptionDetails">Calendar View</div>
                <div className="SubscriptionDetails">24/7 Support</div>
            </div>

            <div className="SubscriptionCard">
                <p className="SubscriptionName">Business</p>
                <p className="SubscriptionPrice">$29</p>
                <p className="SubscriptionInfo">per user, per month</p>
                <div className="SubscriptionPayButton">
                    {/* <Button variant="outline" color="blue" disabled={loading} size="md" onClick={()=>handleCheckout(item_abo_buisness)}>Start My 15-day Trial</Button> */}
                    <Button variant="outline" color="blue" disabled={loading} size="md" onClick={()=>handleCheckout(item_abo_buisness)}>
                        {loading2 ? <Loader color="gray" size="sm" variant="dots" /> : "Start My 15-day Trial"}
                    </Button>
                </div>
                <div className="SubscriptionDetails">10 Social Profiles</div>
                <div className="SubscriptionDetails">25 Scheduled Posts Per Profile</div>
                <div className="SubscriptionDetails">400+ Templates</div>
                <div className="SubscriptionDetails">Calendar View</div>
                <div className="SubscriptionDetails">24/7 VIP Support</div>
            </div>
            
            <div className="SubscriptionCard">
                <p className="SubscriptionName">Agency</p>
                <p className="SubscriptionPrice">$139</p>
                <p className="SubscriptionInfo">per user, per month</p>
                <div className="SubscriptionPayButton">
                    {/* <Button variant="outline" color="blue" disabled={loading} size="md" onClick={()=>handleCheckout(item_abo_agency)}>Start My 15-day Trial</Button> */}
                    <Button variant="outline" color="blue" disabled={loading} size="md" onClick={()=>handleCheckout(item_abo_agency)}>
                        {loading3 ? <Loader color="gray" size="sm" variant="dots" /> : "Start My 15-day Trial"}
                    </Button>
                </div>
                <div className="SubscriptionDetails">100 Social Profiles</div>
                <div className="SubscriptionDetails">100 Scheduled Posts Per Profile</div>
                <div className="SubscriptionDetails">400+ Templates</div>
                <div className="SubscriptionDetails">Calendar View</div>
                <div className="SubscriptionDetails">24/7 VIP Support</div>
            </div>
        </div>
        </>
    )
}

export default Plans;