import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import { useEffect, useRef, useState, useLayoutEffect, createContext } from "react"
import Counter from './screens/Counter';
import Login from './screens/Login';
import Register from './screens/Register';
import ForgotPassword from './screens/ForgotPassword';
import Settings from './screens/Settings/SettingsView';
import ChangeEmail from './screens/Settings/ChangeEmail';
import ChangePassword from './screens/Settings/ChangePassword';
import Plans from './screens/Abos/Plans';
import Success from './screens/Abos/Success';
import Cancel from './screens/Abos/Cancel';
import Subscription from './screens/Abos/Subscription';
import TermsAndConditions from './screens/TermsAndConditions';
import PrivacyPolicy from './screens/PrivacyPolicy';
import SuccessAddPaymentMethod from "./screens/Abos/SuccessAddPaymentMethod"
import Impressum from './screens/Settings/Impressum';
import Bookshelf from './screens/Bookshelf';
import { useSelector } from "react-redux";
import "./mainRun";

function NavigationCentral() {
  const user = useSelector((state) => state.user.user);
  console.log ("Nav User", user)

  return (
    <BrowserRouter>
          <Routes>
            {user ? (
              <>
                <Route path="/" element={<Bookshelf />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/settings/change-email" element={<ChangeEmail />} />
                <Route path="/settings/change-password" element={<ChangePassword />} />
                <Route path="/subscription/plans" element={<Plans />} />
                <Route path="/subscription/plans/success" element={<Success />} />
                <Route path="/subscription/plans/cancel" element={<Cancel />} />
                <Route path="/subscription/success_paymentMethod" element={<SuccessAddPaymentMethod />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/impressum' element={<Impressum />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/impressum' element={<Impressum />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
          </Routes>
    </BrowserRouter>
  )
}

export default NavigationCentral
