import React from "react";

import '../Pages.css';
import './Cancel.css';

import { Button, Text } from '@mantine/core';
import { useNavigate  } from "react-router-dom"

function Cancel(){
    const navigate = useNavigate();
    return(
        <div className='Container'>
            <div className="ContentContainer">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="100" height="100" viewBox="0 0 1280.000000 1280.000000"
                preserveAspectRatio="xMidYMid meet">
                <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
                </metadata>
                <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                fill="red" stroke="none">
                <path d="M3137 9659 l-537 -539 1360 -1360 1360 -1360 -1360 -1360 -1360
                -1360 540 -540 540 -540 1360 1360 1360 1360 1360 -1360 1360 -1360 540 540
                540 540 -1360 1360 -1360 1360 1360 1360 1360 1360 -540 540 -540 540 -1360
                -1360 -1360 -1360 -1360 1360 c-748 748 -1361 1360 -1362 1359 -2 0 -245 -243
                -541 -540z"/>
                </g>
                </svg>
                <br></br>
                <Text className='TitleTextCenter'>Payment Canceled!</Text>
                <Text className='InfoTextCenter'>Your payment has been canceled. No costs were charged.</Text>
                <br></br>
                <div className='Button'>
                    <Button variant="filled" color="dark" fullWidth size="md" onClick={() => {navigate('/');}}>Back to Home</Button>
                </div>
            </div>
        </div>
    )
}

export default Cancel;