import i18next from "i18next";
import { en, de } from "./translations"
import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
    en: {
        translation: en,
    },
    de: {
        translation: de,
    }
};
i18next
.use(LanguageDetector)
.init({
    resources,

    //language to use if translations in user language are not available
    fallbackLng: "en",
    detection: {
        order: ['navigator'],
      },
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
});

export default i18next