import React from "react";

import './SettingsView.css';
import '../Pages.css';

import { useState, useCallback  } from 'react';

import { useNavigate  } from "react-router-dom"
import {auth} from "../../firebase/firebase"
import { Button, Text} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import NavigationBar from "../NavigationBar";
import { useTranslation } from "react-i18next";
import { deleteUserAccount, logout} from "../../sideEffects/sideEffects";
import { Link } from 'react-router-dom';
import { Avatar } from '@mantine/core';

import { BiSolidTrash } from 'react-icons/bi';
import { RiPencilLine } from 'react-icons/ri';

import { useRef} from 'react'
import Cropper from 'react-easy-crop'
import { uploadImage, updateUserProfilePicture } from "../../sideEffects/sideEffects"

import { getCroppedImg, getRotatedImage } from './canvasUtils'

import { useSelector } from "react-redux";

import { getOwnUserObject } from "../../sideEffects/sideEffects";

import { store } from "../../redux/store";
import { setUser } from "../../redux/userSlice";

import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../firebase/firebase";

import { deleteProfilePicture } from "../../sideEffects/sideEffects";
import { useEffect } from "react";

function Settings(){
    const user = useSelector((state) => state.user.user);

    const profileImageRef = useRef()
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const userMail = auth.currentUser?.email;
    const [opened, { open, close }] = useDisclosure(false);

    const [imageSrc, setImageSrc] = useState(null)
    const [openProfileImageModal, setOpenProfileImageModal] = useState(false)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }

    const showCroppedImage = async () => {
        try {
          const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
          )
          console.log('donee', { croppedImage })
          return croppedImage
        } catch (e) {
          console.error(e)
        }
      }

    async function saveNewImage(){
        //Altes Bild löschen
        try{
            const result = await deleteProfilePicture({user: user})
        }catch(e){
            console.log(e)
        }
        
        //Neues Bild zuschendien und speichern
        try {
            const croppedImage = await showCroppedImage();
            const result = await uploadImage(croppedImage)
            setOpenProfileImageModal(false)
            //setImageSrc(null)
            updateUserProfilePicture({
                id: result.id,
                url: result.url
            })

        }catch(e){
            console.error(e)
        }
        try{
            const result = await getOwnUserObject();
            store.dispatch(setUser(result));
        }catch(e){
            console.log(e)
        }
        
    }

    function changePassword(){
        navigate('/settings/change-password');
    }

    function changeEmail(){
        navigate('/settings/change-email');
    }

    async function deleteAccount(){
        try{
            await deleteUserAccount();
            navigate("/")
        } catch (error) {
            console.log("error", error.message)
        }
    }

    function Abos(){
        navigate('/subscription');
    }

    async function Logout(){
        try{
            setError(false)
            setError(true)
            setLoading(true)
            await logout()
            navigate('/');
          }catch{
            setError(true)
          }
            setLoading(false)
    }


    function handleProfileImageClick(e) {
        e.stopPropagation()
        profileImageRef.current.click();
    }

    const handleProfileImageUpload = (e) => {


        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          setImageSrc(reader.result);
          setOpenProfileImageModal(true)
        };
        reader.readAsDataURL(file);        
      };

    //   useEffect(() => {
    //     profileImageRef.current.value = ""
    //   }, [imageSrc])

    return(
        <div className='Container'>
            <NavigationBar showArrow={true} showAvater={false} LinkAvatar="/settings" LinkArrow="/"/>
            <Modal opened={opened} onClose={close} title={t("deleteAccount")} size="238px" centered>
                <div className="DeleteView">
                    <Button variant="filled" color="dark" size="sm" onClick={() => {close()}}>{t("no")}</Button>
                    <Button variant="filled" color="red" size="sm" onClick={() => {deleteAccount()}}>{t("yes")}, {t("deleteAccount")}</Button>
                </div>
            </Modal>
            <Modal opened={openProfileImageModal} onClose={() => setOpenProfileImageModal(false)} centered>
                <div className="ModalContainer">

                        <Cropper className="CropperContainer"
                            image={imageSrc}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={1 / 1}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            cropShape={"round"}
                            showGrid={false}
                            style={{ 
                                containerStyle: {
                                    // background:"yellow",
                                    height: "95%",
                                }, 
                                mediaStyle: {
                                    // background:"green"
                                }, 
                                cropAreaStyle: {
                                    // background:"red"
                      
                                } 
                            }}
                        />
                </div>
                <Button variant="transparent" color="gray" radius="lg" onClick={() => setOpenProfileImageModal(false)}>{t("cancel")}</Button>
                <Button variant="filled" radius="md" onClick={() => (saveNewImage())}>{t("saveImage")}</Button>

            </Modal>
            <div className="ContentContainer">
                <Text className='TitleText'>{t("setting")}</Text>
                <Text className='InfoText'>{t("settingInfo")}</Text>
                <Text className='InfoText'>{t("accountEmail")} {userMail}</Text>
                <div className="avatar">
                    <Avatar src={user.profile_picture_url} radius="90px" size="128px" color="dark" className="avatar-hover" onClick={handleProfileImageClick}/>
                </div>
                <div className="ProfilePictureActions">
                    <div className="DeleteProfilePictureButton">
                        <Button variant="transparent" color="red" className="DeleteText" onClick={()=>deleteProfilePicture({user: user})}>
                            <BiSolidTrash /> {t("delete")}
                        </Button>
                    </div>
                    <div className="ChangeProfilePictureButton">
                        <Button variant="transparent" color="red" className="ChangeText" onClick={handleProfileImageClick}>
                            <RiPencilLine /> {t("change")}
                        </Button>
                    </div>
                </div>

                <input
                    style={{ display: 'none' }}
                    type="file"
                    ref={profileImageRef}
                    onChange={handleProfileImageUpload}
                />

                <div className='Button'>
                    <Button variant="outline" color="gray" fullWidth size="md" onClick={() => {Logout()}}>{t("logout")}</Button>
                </div>
                <div className='Button'>
                    <Button variant="filled" color="dark" fullWidth size="md" onClick={() => {changeEmail()}}>{t("changeEmail")}</Button>
                </div>
                <div className='Button'>
                    <Button variant="filled" color="dark" fullWidth size="md" onClick={() => {changePassword()}}>{t("changePassword")}</Button>
                </div>
                <div className='Button'>
                    <Button variant="filled" color="dark" fullWidth size="md" onClick={() => {Abos()}}>{t("subscriptions")}</Button>
                </div>
            </div>
            <div className="Links">
                    <div className="Link" onClick={open}>
                        <p>{t("deleteAccount")}</p>
                    </div>
                    <Link to="/impressum" className="Link">
                        <p>{t("impressum")}</p>
                    </Link>
                    <Link to="/privacy-policy" className="Link">
                        <p>{t("privacyPolicy")}</p>
                    </Link>
                    <Link to="/terms-and-conditions" className="Link">
                        <p>{t("termsAndConditions")}</p>
                    </Link>
                </div>
        </div>
    )
}

export default Settings;