import React from "react";

import '../Pages.css';

import { Button, Text, PasswordInput } from '@mantine/core';
import { useState } from 'react';
import NavigationBar from "../NavigationBar";
import { useTranslation } from "react-i18next";
import { SetNewPassword } from "../../sideEffects/sideEffects";
import { useNavigate  } from "react-router-dom"

function ChangePassword(){
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    function changePassword(){
        if(password===password2){
            SetNewPassword(password);
            navigate('/');
        }else{
            console.log("ungleiche Eingabe")
        }
    }

    return(
        <div className='Container'>
            <NavigationBar showArrow={true} showAvater={true} LinkAvatar="/settings" LinkArrow="/settings"/>
            <div className="ContentContainer">
                <Text className='TitleText'>{t("changePassword")}</Text>
                <br></br>
                <div className='ContextInputs'>
                    <PasswordInput
                        className='LoginInput'
                        placeholder={t("password")}
                        description={t("auth/weak-password")}
                        value={password}
                        size="md"
                        variant="filled"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    {password===password2 ?
                    <PasswordInput
                        className='LoginInput'
                        placeholder={t("passwordRepeat")}
                        value={password2}
                        size="md"
                        variant="filled"
                        onChange={(event) => setPassword2(event.target.value)}
                    />
                    :
                    <PasswordInput
                        className='LoginInput'
                        placeholder={t("passwordRepeat")}
                        value={password2}
                        size="md"
                        variant="filled"
                        error
                        onChange={(event) => setPassword2(event.target.value)}
                    />
                    }
                    </div>
                <br></br>
                <div className='Button'>
                    <Button fullWidth color="dark" size="md" onClick={() => {changePassword()}}>{t("changePassword")}</Button>
                </div>
                {/* <div className="ExtraLink">
                    <p>Everything's ok?{' '}
                        <Link to="/settings">Go back</Link>
                    </p>
                </div> */}
            </div>
        </div>
    );
}

export default ChangePassword;